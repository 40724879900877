import React, { useEffect, useState } from 'react';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../context/configurationContext';
import { Icons } from '../../../components';
import { PRICE } from '../../../constants';

import css from './FilterPills.module.css';
import { isArrayLength } from '../../../util/genericHelpers';

// Parse initial filters and remove 'has_all:' or 'has_any:'
const parseFilters = filters => {
  const parsed = {};
  Object.keys(filters).forEach(key => {
    let value = filters[key];
    // Convert non-string, non-array values to an array
    parsed[key] = Array.isArray(value) ? value : [value];
  });

  if (parsed?.pub_freeShipping) {
    delete parsed['pub_freeShipping'];
  }

  return parsed;
};

export const FilterPills = ({ history, initialFilters }) => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const listingFields = config.listing.listingFields;

  const allEnumOptions =
    isArrayLength(listingFields) && listingFields.flatMap(field => field.enumOptions);

  const [filters, setFilters] = useState(parseFilters(initialFilters));

  useEffect(() => {
    setFilters(parseFilters(initialFilters));
  }, [initialFilters]);

  const removeFilter = (key, value) => {
    const updated = { ...filters };
    updated[key] = updated[key][0].split(',').filter(item => item.trim() !== value);
    if (updated[key].length === 0) {
      delete updated[key];
    }
    // If the key is 'price', reassign it with the formatted value
    if (key === PRICE) {
      updated[PRICE] = [];
    }
    setFilters(updated);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, updated));
  };

  const getLabel = (value, key) => {
    if (typeof value === 'boolean') {
      return (
        <div key={key} className={css.pill}>
          {value ? 'True' : 'False'}
          <span className={css.remove} onClick={() => removeFilter(key, value)}>
            &times;
          </span>
        </div>
      );
    } else if (typeof value === 'string') {
      const isRange = /^\d+,\d+$/.test(value);
      const values = isRange
        ? [
            value
              ?.split(',')
              ?.map(val => `$${val?.trim()}`)
              ?.join('-'),
          ]
        : value?.split(',');

      return values.map((val, index) => {
        const label = allEnumOptions?.find(o => o?.option === val)?.label || val;

        return (
          <div key={`${key}-${index}`} className={css.pill}>
            {label}
            <span className={css.remove} onClick={() => removeFilter(key, val.trim())}>
              &times;
            </span>
          </div>
        );
      });
    } else {
      console.error('Expected value to be a string or boolean, but received:', value);
      return null; // or handle this case appropriately
    }
  };

  return (
    <div className={css.pillsWrapper}>
      {Object.entries(filters).map(([key, values]) =>
        values.map(value => (
          <React.Fragment key={`${key}-${value}`}>{getLabel(value, key)}</React.Fragment>
        ))
      )}
    </div>
  );
};
