import React, { useEffect, useState } from 'react';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { quickFilters } from '../../../util/configHelpers';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import css from './QuickFilters.module.css';

// Parse initial filters and ensure all values are arrays
const parseFilters = filters => {
  const parsed = {};
  Object.keys(filters).forEach(key => {
    let value = String(filters[key]);
    parsed[key] = value?.split(','); // Split comma-separated values into arrays
  });
  return parsed;
};

const QuickFilters = ({ history, initialFilters }) => {
  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();

  const [filters, setFilters] = useState(parseFilters(initialFilters));

  useEffect(() => {
    const parsedFilters = queryString.parse(location.search);
    setFilters(parseFilters(parsedFilters));
  }, [location.search]);

  const handleFilterClick = filter => {
    const { search } = filter;
    const [key, value] = search.split('=');

    const updatedFilters = { ...filters };
    if (updatedFilters[key] && updatedFilters[key].includes(value)) {
      // If filter is already selected, remove it
      updatedFilters[key] = updatedFilters[key].filter(item => item !== value);
      if (updatedFilters[key].length === 0) {
        delete updatedFilters[key];
      }
    } else {
      // If filter is not selected, add it
      if (updatedFilters[key]) {
        updatedFilters[key].push(value);
      } else {
        updatedFilters[key] = [value];
      }
    }

    setFilters(updatedFilters);
    const encodedFilters = Object.keys(updatedFilters).reduce((acc, key) => {
      acc[key] = updatedFilters[key].join('%2C'); // Encode values as comma-separated strings
      return acc;
    }, {});
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, encodedFilters));
  };

  const isFilterSelected = filter => {
    const { search } = filter;
    const [key, value] = search.split('=');
    return filters[key] && filters[key].includes(value);
  };

  return (
    <div className={css.quickFilters}>
      {quickFilters?.map(f => (
        <div
          key={f.name}
          className={`${css.quickFilter} ${isFilterSelected(f) ? css.selected : ''}`}
          onClick={() => handleFilterClick(f)}
        >
          {f.icon}
          {f.name}
        </div>
      ))}
    </div>
  );
};

export default QuickFilters;
