import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { Button, Icons } from '../../../components';
import KeywordFilter from '../KeywordFilter/KeywordFilter';
import { KEYWORDS } from '../../../constants';
import { generateFilterComponentProperties } from '../searchPageHelpers';
import { FilterPills } from '../FilterPills/FilterPills';
import QuickFilters from '../QuickFilters/QuickFilters';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    onToggleModal,
    idPrefix,
    intl,
    initialValues,
    getHandleChangedValueFn,
    liveEdit,
    showAsPopup,
    pageView,
    history,
    validQueryParams,
    listingFieldsConfig,
    resetAll,
    ...rest
  } = props;

  const { componentId, key, name, useHistoryPush } = generateFilterComponentProperties({
    key: KEYWORDS,
    initialValues,
    getHandleChangedValueFn,
    intl,
    liveEdit,
    showAsPopup,
    idPrefix: 'SearchFiltersWeb',
  });
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {/* <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div> */}
        <div className={css.leftFilters}>
          <QuickFilters history={history} initialFilters={validQueryParams} />
        </div>
        {isSortByActive ? (
          <div className={css.sortyByWrapper}>
            <KeywordFilter
              rootClassName={css.keywordFilter}
              isSearchKeywordFilter={true}
              id={componentId}
              // label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
              name={name}
              queryParamNames={[key]}
              initialValues={initialValues([key], liveEdit)}
              onSubmit={getHandleChangedValueFn(useHistoryPush)}
              {...rest}
            />
            <Button className={css.filterButton} onClick={onToggleModal}>
              <Icons name="filter" />
              <FormattedMessage id="MainPanelHeader.filter" />
            </Button>
            {sortByComponent}
            {pageView}
          </div>
        ) : null}
      </div>

      <FilterPills history={history} initialFilters={validQueryParams} />
      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
