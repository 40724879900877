import { CATEGORY, COLOR, CONDITION, PRICE, SIZE } from '../../constants';

const priorityOrder = [CATEGORY, PRICE, SIZE, COLOR, CONDITION];
export function sortByPriority(dataArray) {
  const customSort = (a, b) => {
    const keyA = a.key.toLowerCase();
    const keyB = b.key.toLowerCase();
    const indexA = priorityOrder.indexOf(keyA);
    const indexB = priorityOrder.indexOf(keyB);

    if (indexA === -1) return 1; // Place objects without the specified keys at the end
    if (indexB === -1) return -1; // Place objects without the specified keys at the end

    return indexA - indexB;
  };

  return dataArray.slice().sort(customSort);
}

export function generateFilterComponentProperties({
  key,
  idPrefix = 'SearchPage',
  liveEdit = false,
  showAsPopup = false,
}) {
  const componentId = `${idPrefix}.${key.toLowerCase()}`;
  const useHistoryPush = liveEdit || showAsPopup;
  const name = key.replace(/\s+/g, '-').toLowerCase();

  return {
    key,
    componentId,
    useHistoryPush,
    name,
  };
}
